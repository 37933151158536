import type firebase from "firebase/app";
import { ExtendableError } from "../../utils/ExtendableError";

export async function getAccessToken(args: AccessTokenArgs): Promise<string> {
  return getAccessTokenDocReference(args)
    .get()
    .then((doc) => accessTokenFromSnapshot(doc));
}

export async function saveAccessToken({
  accessToken,
  shopId,
  firestore,
}: {
  accessToken: string;
  shopId: string;
  firestore: FirebaseFirestore.Firestore;
}): Promise<void> {
  await getAccessTokenDocReference({ shopId, firestore }).set({
    accessToken: accessToken,
  });
}

export class AccessTokenFetchError extends ExtendableError {}

function getAccessTokenDocReference({ shopId, firestore }: AccessTokenArgs) {
  return firestore.collection("accessTokens").doc(shopId);
}

function accessTokenFromSnapshot(
  doc: FirebaseFirestore.DocumentSnapshot | firebase.firestore.DocumentSnapshot
) {
  const data = doc.data();
  if (!doc.exists || !data) {
    throw new AccessTokenFetchError("Access token does not exist");
  }
  const { accessToken } = data;
  if (accessToken === undefined) {
    throw new AccessTokenFetchError("Access token missing from document");
  }
  return accessToken;
}

interface AccessTokenArgs {
  shopId: string;
  firestore: FirebaseFirestore.Firestore | firebase.firestore.Firestore;
}
