import { ResourceId, ResourceType, Variant } from "../../types";
import { shopifyGraphqlRequest } from "../admin/shared";
import { proxyShopifyGraphqlRequest } from "../proxyRequest";
import { getIdFromGqlId, GqlResponseParser, parseGqlResponse } from "./shared";

export async function getShopifyActiveVariantWithMostInStock({
  shopId,
  accessToken,
  resourceIdsToGetVariantFrom,
}: {
  shopId: string;
  accessToken: string;
  resourceIdsToGetVariantFrom?: ResourceId[];
}): Promise<Variant | null> {
  const resourceIdsFilter = getResourceFiltersFromIds({
    resourceIds: resourceIdsToGetVariantFrom,
  });
  const searchQuery = `(inventory_quantity:>0 OR managed:false) AND product_status:active ${
    resourceIdsFilter ? `AND ${resourceIdsFilter}` : ""
  }`;
  return await proxyShopifyGraphqlRequest({
    shopId,
    accessToken,
    query: getVariantWithMostInStockQuery({ searchQuery }),
  }).then((gqlRes) =>
    parseGqlResponse({
      gqlRes,
      parser: getVariantWithMostInStockFromGqlResponse,
    })
  );
}

export async function getShopifyVariantById({
  shopId,
  accessToken,
  variantId,
  useProxy,
}: {
  shopId: string;
  accessToken: string;
  variantId: string;
  useProxy: boolean;
}): Promise<Variant> {
  const fetcher = useProxy ? proxyShopifyGraphqlRequest : shopifyGraphqlRequest;
  return await fetcher({
    shopId,
    accessToken,
    query: getVariantByIdQuery({ variantId }),
  }).then((gqlRes) =>
    parseGqlResponse({ gqlRes, parser: getVariantByIdFromGqlResponse })
  );
}

export async function getShopifyVariantFromProductId({
  shopId,
  accessToken,
  productId,
}: {
  shopId: string;
  accessToken: string;
  productId: string;
}): Promise<Variant> {
  return await proxyShopifyGraphqlRequest({
    shopId,
    accessToken,
    query: getProductVariantQuery({ productId }),
  }).then((gqlRes) =>
    parseGqlResponse({ gqlRes, parser: getVariantFromProductVariantGqlRes })
  );
}

export async function getShopifyVariantFromCollectionId({
  shopId,
  accessToken,
  collectionId,
}: {
  shopId: string;
  accessToken: string;
  collectionId: string;
}): Promise<Variant> {
  return await proxyShopifyGraphqlRequest({
    shopId,
    accessToken,
    query: getCollectionVariantQuery({ collectionId }),
  }).then((gqlRes) =>
    parseGqlResponse({ gqlRes, parser: getVariantFromCollectionVariantGqlRes })
  );
}

function getCollectionVariantQuery({ collectionId }: { collectionId: string }) {
  return `{
    collection(id:"gid://shopify/Collection/${collectionId}") {
      products(first:1) {
        edges {
          node {
            variants(first:1) {
              edges {
                node {
                  ${getVariantNodeQuery()}
                }
              }
            }
          }
        }
      }
    }
  }`;
}

function getVariantByIdQuery({ variantId }: { variantId: string }) {
  return `{
    productVariant(id: "gid://shopify/ProductVariant/${variantId}") {
      ${getVariantNodeQuery()}
    }
  }`;
}

function getProductVariantQuery({ productId }: { productId: string }) {
  return `{
      product(id: "gid://shopify/Product/${productId}") {
        variants(first:1) {
          edges {
            node {
              ${getVariantNodeQuery()}
            }
          }
        }
      }
    }
    `;
}

function getVariantWithMostInStockQuery({
  searchQuery,
}: {
  searchQuery: string;
}) {
  return `{
    productVariants(first:1, query:"${searchQuery}", sortKey:INVENTORY_QUANTITY, reverse:true) {
      edges {
        node {
          ${getVariantNodeQuery()}
        }
      }
    }
  }
  `;
}

export function getVariantNodeQuery() {
  return `
    id
    title
    image {
      transformedSrc
    }
    price
    compareAtPrice
    inventoryQuantity
    inventoryItem {
      tracked
    }
    inventoryPolicy
  `;
}

const getVariantByIdFromGqlResponse: GqlResponseParser<Variant> = ({
  data,
}) => {
  return getVariantFromNode(data.productVariant);
};

const getVariantWithMostInStockFromGqlResponse: GqlResponseParser<Variant | null> = ({
  data,
}) => {
  const variantEdges = data.productVariants.edges;
  if (variantEdges.length === 0) {
    return null;
  }
  const variantNode = variantEdges[0].node;
  return getVariantFromNode(variantNode);
};

const getVariantFromProductVariantGqlRes: GqlResponseParser<Variant> = ({
  data,
}) => {
  const variantNode = data.product.variants.edges[0].node;
  return getVariantFromNode(variantNode);
};

const getVariantFromCollectionVariantGqlRes: GqlResponseParser<Variant> = ({
  data,
}) => {
  const variantNode =
    data.collection.products.edges[0].node.variants.edges[0].node;
  return getVariantFromNode(variantNode);
};

export function getVariantFromNode(node: any): Variant {
  const img = node.image ? node.image.transformedSrc : "";
  const { compareAtPrice } = node;
  return {
    id: getIdFromGqlId(node.id),
    name: node.title,
    imgSrc: img,
    price: Number(node.price),
    compareAtPrice:
      typeof compareAtPrice === "string" ? Number(compareAtPrice) : null,
    nInStock: node.inventoryQuantity,
    inventoryIsTracked: node.inventoryItem.tracked,
    shouldSellWhenOutOfStock: node.inventoryPolicy === "CONTINUE",
  };
}

export function getResourceFiltersFromIds({
  resourceIds,
}: {
  resourceIds?: ResourceId[];
}): string | null {
  if (!resourceIds || resourceIds.length === 0) return null;
  const query = resourceIds.map(getSearchQueryFromResourceId).join(" OR ");
  return `(${query})`;
}

function getSearchQueryFromResourceId(resourceId: ResourceId): string {
  switch (resourceId.type) {
    case ResourceType.product:
      return `product_id:${resourceId.id}`;
    case ResourceType.collection:
      return `collection:${resourceId.id}`;
  }
}
