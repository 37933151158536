import { Collection, ResourceType } from "../../types";
import { proxyShopifyGraphqlRequest } from "../proxyRequest";
import { getMultipleProductsQuery, getShopifyProductFromNode } from "./product";
import {
  getIdFromGqlId,
  getLastCursorFromEdges,
  GqlResponseParser,
  PaginatedGraphqlResponse,
  parseGqlResponse,
} from "./shared";

export async function getShopifyMultipleCollectionsShallow({
  accessToken,
  shopId,
  nCollections,
  nProducts,
  nVariantsPerProduct,
  cursor,
  searchQuery,
}: {
  accessToken: string;
  shopId: string;
  nCollections: number;
  nProducts: number;
  nVariantsPerProduct: number;
  searchQuery: string;
  cursor: string | null;
}): Promise<PaginatedGraphqlResponse<Collection>> {
  return proxyShopifyGraphqlRequest({
    accessToken,
    shopId,
    query: getMultipleShallowCollectionQuery({
      nCollections,
      nProducts,
      nVariantsPerProduct,
      searchQuery,
      cursor,
    }),
  }).then((gqlRes) =>
    parseGqlResponse({
      gqlRes,
      parser: getMultipleShallowCollectionsFromGqlResponse,
    })
  );
}

export async function getShopifyCollectionSemiShallow({
  accessToken,
  shopId,
  collectionId,
  nProducts,
  nVariantsPerProduct,
}: {
  accessToken: string;
  shopId: string;
  collectionId: string;
  nProducts: number;
  nVariantsPerProduct: number;
}): Promise<Collection> {
  return proxyShopifyGraphqlRequest({
    accessToken,
    shopId,
    query: getSemiShallowCollectionQuery({
      collectionId,
      nProducts,
      nVariantsPerProduct,
    }),
  }).then((gqlRes) =>
    parseGqlResponse({ gqlRes, parser: getShallowCollectionFromGqlResponse })
  );
}

function getMultipleShallowCollectionQuery({
  nCollections,
  nProducts,
  nVariantsPerProduct,
  searchQuery,
  cursor,
}: {
  nCollections: number;
  nProducts: number;
  nVariantsPerProduct: number;
  searchQuery: string;
  cursor: string | null;
}) {
  const cursorString = cursor ? `, after: "${cursor}"` : "";
  const query = searchQuery ? `, query: "*${searchQuery}*"` : "";
  return `{
      collections(first:${nCollections}${cursorString}${query}) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              ${getSemiShallowCollectionNodeQuery({
                nProducts,
                nVariantsPerProduct,
              })}
            }
          }
        }
    }`;
}

function getSemiShallowCollectionQuery({
  collectionId,
  nProducts,
  nVariantsPerProduct,
}: {
  collectionId: string;
  nProducts: number;
  nVariantsPerProduct: number;
}) {
  return `{
        collection(id:"gid://shopify/Collection/${collectionId}") {
            ${getSemiShallowCollectionNodeQuery({
              nProducts,
              nVariantsPerProduct,
            })}
        }
    }`;
}

function getSemiShallowCollectionNodeQuery({
  nProducts,
  nVariantsPerProduct,
}: {
  nProducts: number;
  nVariantsPerProduct: number;
}) {
  return `
    id
    title
    image {
        transformedSrc
    }
    productsCount
    ${getMultipleProductsQuery({
      nProducts,
      nVariantsPerProduct,
      searchQuery: "",
      cursor: null,
      includeBrackets: false,
    })}
    `;
}

const getMultipleShallowCollectionsFromGqlResponse: GqlResponseParser<
  PaginatedGraphqlResponse<Collection>
> = ({ data }) => {
  const { edges, pageInfo } = data.collections;
  const parsedCollections: Collection[] = [];
  for (const { node } of edges) {
    const collection = getShallowCollectionFromNode({ node });
    parsedCollections.push(collection);
  }
  return {
    lastCursor: getLastCursorFromEdges({ edges }),
    data: parsedCollections,
    hasMoreItems: pageInfo.hasNextPage,
  };
};

const getShallowCollectionFromGqlResponse: GqlResponseParser<Collection> = ({
  data,
}) => {
  return getShallowCollectionFromNode({ node: data.collection });
};

function getShallowCollectionFromNode({ node }: { node: any }): Collection {
  const img = node.image?.transformedSrc;
  return {
    id: getIdFromGqlId(node.id),
    imgSrc: img ? img : "",
    type: ResourceType.collection,
    name: node.title,
    products: node.products.edges.map((edge: any) =>
      getShopifyProductFromNode({ node: edge.node })
    ),
    nProducts: node.productsCount,
  };
}
