import { shopifyApiVersion } from "./admin/shared";

export async function proxyShopifyGraphqlRequest({
  shopId,
  accessToken,
  query,
}: ShopifyGraphqlRequest) {
  return await fetch(
    `/api/gql/proxy?shopId=${shopId}&shopifyApiVersion=${shopifyApiVersion}&accessToken=${accessToken}&query=${query}`
  ).then((res) => res.json());
}
interface ShopifyGraphqlRequest {
  shopId: string;
  accessToken: string;
  query: string;
}
