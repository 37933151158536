import { useEffect, useState } from "react";
import { getAccessToken } from "../../../../../shared/src/firebase/firestore/accessTokens";
import { useFirestore } from "./useFirestore";
import { useShopId } from "./useShopId";

export function useAccessToken() {
  const shopId = useShopId();
  const firestore = useFirestore();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    if (!shopId || !firestore) return;
    let cancelled = false;
    getAccessToken({ shopId, firestore }).then((accessToken) => {
      if (cancelled) return;
      setAccessToken(accessToken);
    });
    return () => {
      cancelled = true;
    };
  }, [shopId, firestore]);

  return accessToken;
}
