import { ResourceId, ResourceType } from "../../types";
import { getShopifyCollectionSemiShallow } from "./collection";
import { getShopifyProductSemiShallow } from "./product";

export function parseGqlResponse<T>({
  gqlRes,
  parser,
}: {
  gqlRes: any;
  parser: GqlResponseParser<T>;
}): T {
  const { data, errors } = gqlRes;
  try {
    return parser({ data });
  } catch (e) {
    console.error(errors);
    throw e;
  }
}

export async function getResourceName({
  resourceId,
  accessToken,
  shopId,
}: {
  resourceId: ResourceId;
  accessToken: string;
  shopId: string;
}): Promise<string> {
  switch (resourceId.type) {
    case ResourceType.product:
      const triggerProduct = await getShopifyProductSemiShallow({
        accessToken,
        shopId,
        productId: resourceId.id,
        nVariants: 0,
      });
      return triggerProduct.name;
    case ResourceType.collection:
      const triggerCollection = await getShopifyCollectionSemiShallow({
        accessToken,
        shopId,
        collectionId: resourceId.id,
        nProducts: 0,
        nVariantsPerProduct: 0,
      });
      return triggerCollection.name;
  }
}

export function getIdFromGqlId(gqlId: string): string {
  const matches = gqlId.match(/[0-9]+/);
  if (matches === null) {
    throw new Error(`No id match for gql id ${gqlId}`);
  }
  const id = matches[0];
  if (id === undefined) {
    throw new Error(`No id match for gql id ${gqlId}`);
  }
  return id;
}

export type GqlResponseParser<T> = (args: { data: any }) => T;

export interface PaginatedGraphqlResponse<T> {
  hasMoreItems: boolean;
  lastCursor: string | null;
  data: T[];
}

export function getLastCursorFromEdges({
  edges,
}: {
  edges: any[];
}): string | null {
  try {
    return edges[edges.length - 1].cursor;
  } catch (e) {
    return null;
  }
}
