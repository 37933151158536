import fetch from "node-fetch";
export const shopifyApiVersion = "2021-07";

export async function shopifyGraphqlRequest<T>({
  shopId,
  accessToken,
  query,
  variables,
}: ShopifyGraphqlRequest<T>): Promise<any> {
  return await fetch(
    `https://${shopId}/admin/api/${shopifyApiVersion}/graphql.json`,
    {
      method: "POST",
      headers: {
        "X-Shopify-Access-Token": accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query, variables }),
    }
  ).then((res) => res.json());
}

export async function shopifyRestRequest<T>({
  shopId,
  accessToken,
  endpoint,
}: {
  shopId: string;
  accessToken: string;
  endpoint: string;
}): Promise<T> {
  return fetch(`https://${shopId}/admin/api/${shopifyApiVersion}/${endpoint}`, {
    headers: {
      "X-Shopify-Access-Token": accessToken,
    },
  }).then((res) => res.json());
}

interface ShopifyGraphqlRequest<T> {
  shopId: string;
  accessToken: string;
  query: string;
  variables?: T;
}
